var render = function render(){
  var _vm$provider, _vm$provider$nodes, _vm$provider2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "provider-details"
  }, [_c('div', {
    staticClass: "provider-info mt-2"
  }, [_c('div', {
    staticClass: "mb-3 provider-description"
  }, [_vm.provider ? _c('div', [_vm._v(" " + _vm._s(_vm.provider.description) + " ")]) : _c('div', {
    staticClass: "preloader text-preloader"
  })]), _c('div', {
    staticClass: "d-flex justify-content-start mt-3 mb-2 provider-values"
  }, [_c('LabelValue', {
    attrs: {
      "label": "Nodes",
      "value": _vm.provider && (_vm$provider = _vm.provider) !== null && _vm$provider !== void 0 && (_vm$provider$nodes = _vm$provider.nodes) !== null && _vm$provider$nodes !== void 0 && _vm$provider$nodes.length ? _vm.provider.nodes.length : '0',
      "alignRight": true
    }
  }), _c('LabelValue', {
    attrs: {
      "label": "Assets",
      "value": _vm.provider && (_vm$provider2 = _vm.provider) !== null && _vm$provider2 !== void 0 && _vm$provider2.assetsCount ? _vm.provider.assetsCount : '0',
      "alignRight": true
    }
  }), _c('LabelValue', {
    attrs: {
      "label": "Interval",
      "value": _vm.provider && _vm.provider.currentManifest ? _vm.formatInterval(_vm.provider.currentManifest.interval) : undefined,
      "alignRight": true
    }
  })], 1)]), _c('hr'), _c('div', [_c('div', {
    staticClass: "table-title mt-4 mb-2"
  }, [_vm._v("Provided data:")]), _vm.dataServiceId !== 'redstone-custom-urls-demo' ? _c('b-table', {
    attrs: {
      "id": "assets-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.visibleTokens,
      "fields": _vm.fieldsFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_c('img', {
          staticClass: "token-logo",
          attrs: {
            "src": data.item.logoURI || _vm.logoPlaceholder
          }
        }), _c('span', {
          staticClass: "token-name ml-3"
        }, [_vm._v(_vm._s(data.item.name))])];
      }
    }, {
      key: "cell(symbol)",
      fn: function fn(data) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-truncate d-block",
          attrs: {
            "title": data.item.symbol
          }
        }, [_vm._v(" " + _vm._s(data.item.symbol) + " ")])];
      }
    }, {
      key: "cell(sources)",
      fn: function fn(data) {
        return [_c('div', {
          ref: 'symbols_' + data.item.symbol,
          staticClass: "d-flex source-links-wrapper"
        }, [_c('div', {
          staticClass: "d-flex source-links"
        }, _vm._l(data.item.source, function (source) {
          return _c('a', {
            key: source.symbol,
            staticClass: "source-link mb-2 mb-md-0",
            attrs: {
              "target": "_blank",
              "href": source.url
            }
          }, [_c('img', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "source-logo",
            attrs: {
              "src": source.logoURI || _vm.logoPlaceholder,
              "title": source.name
            }
          })]);
        }), 0)])];
      }
    }], null, false, 104303512)
  }) : _vm._e(), !_vm.allTokensVisible ? _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.loadMoreSectionVisibilityChanged,
      expression: "loadMoreSectionVisibilityChanged"
    }]
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader token-preloader"
    });
  }), 0) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }