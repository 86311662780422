var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crypto-dropdown-container"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "dropdown crypto-dropdown",
    attrs: {
      "text": _vm.buttonText,
      "multiple": ""
    },
    on: {
      "shown": _vm.onDropdownShown
    }
  }, [_c('div', {
    staticClass: "search-input-container"
  }, [_c('b-form-input', {
    ref: "searchInput",
    staticClass: "pr-4",
    attrs: {
      "variant": "danger",
      "placeholder": _vm.searchPlaceholder
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _c('b-dropdown-form', [_c('b-form-checkbox-group', {
    staticClass: "crypto-checkbox-group",
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.tempSelected,
      callback: function callback($$v) {
        _vm.tempSelected = $$v;
      },
      expression: "tempSelected"
    }
  }, _vm._l(_vm.filteredItems, function (item) {
    return _c('b-form-checkbox', {
      key: "".concat(item.value, "-").concat(item.label),
      staticClass: "crypto-checkbox-list",
      attrs: {
        "variant": "danger",
        "value": item.value
      }
    }, [_c('span', {
      staticClass: "network-name"
    }, [_vm._v(_vm._s(item.label))])]);
  }), 1), _vm.filteredItems.length === 0 ? _c('span', {
    staticClass: "no-results"
  }, [_vm._v(_vm._s(_vm.noResultsText))]) : _vm._e()], 1), _vm.hasChanges ? _c('div', {
    staticClass: "confirm-button-container"
  }, [_c('b-button', {
    staticClass: "confirm-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.confirmChanges
    }
  }, [_c('i', {
    staticClass: "fa fa-icon fa-sliders mr-2"
  }), _vm._v("Filter (" + _vm._s(_vm.tempSelected.length) + ") ")])], 1) : _vm.value.length > 0 ? _c('div', {
    staticClass: "confirm-button-container"
  }, [_c('b-button', {
    staticClass: "reset-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.resetChanges
    }
  }, [_vm._v("Reset (" + _vm._s(_vm.tempSelected.length) + ")")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }