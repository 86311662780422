var render = function render(){
  var _vm$provider$nodes, _vm$provider;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "provider d-flex flex-column"
  }, [_vm.provider ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('img', {
    staticClass: "provider-logo",
    attrs: {
      "src": _vm.provider.logo
    }
  }), _c('div', {
    staticClass: "provider-name ml-3"
  }, [_vm._v(_vm._s(_vm.provider.name))])]), _c('div', {
    staticClass: "provider-id mb-3"
  }, [_vm._v("id: " + _vm._s(_vm.providerId))])]) : _c('div', {
    staticClass: "preloaders"
  }, [_c('div', {
    staticClass: "preloader logo-preloader"
  }), _c('div', {
    staticClass: "preloader text-preloader"
  })]), _c('div', {
    staticClass: "provider-tabs"
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Details"
    }
  }, [_c('DataServiceDetails', {
    attrs: {
      "provider": _vm.provider
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Nodes"
    }
  }, [_c('Nodes', {
    attrs: {
      "nodes": (_vm$provider$nodes = (_vm$provider = _vm.provider) === null || _vm$provider === void 0 ? void 0 : _vm$provider.nodes) !== null && _vm$provider$nodes !== void 0 ? _vm$provider$nodes : []
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }