var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "price-table"
  }, [_c('div', {
    staticClass: "table-title"
  }, [_vm._v("Data services")]), _c('div', {
    staticClass: "table-filters-container mt-4 mb-4 d-flex justify-content-start"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "12",
      "md": "6"
    }
  }, [_c('b-form', {
    attrs: {
      "inline": ""
    }
  }, [_c('div', {
    staticClass: "datepicker-container"
  }, [_c('label', {
    staticClass: "mt-2 mt-md-0",
    attrs: {
      "for": "from-datepicker"
    }
  }, [_vm._v("Show services from: ")]), _c('b-datepicker', {
    attrs: {
      "id": "from-datepicker",
      "value-as-date": true,
      "locale": "en-GB",
      "max": _vm.toDate,
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.fromDate,
      callback: function callback($$v) {
        _vm.fromDate = $$v;
      },
      expression: "fromDate"
    }
  }), _c('b-form-timepicker', {
    attrs: {
      "locale": "en",
      "no-close-button": ""
    },
    model: {
      value: _vm.fromTime,
      callback: function callback($$v) {
        _vm.fromTime = $$v;
      },
      expression: "fromTime"
    }
  })], 1)])], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "6"
    }
  }, [_c('b-form', {
    attrs: {
      "inline": ""
    }
  }, [_c('div', {
    staticClass: "datepicker-container"
  }, [_c('label', {
    staticClass: "mt-2 mt-md-0",
    attrs: {
      "for": "to-datepicker"
    }
  }, [_vm._v("to:")]), _c('b-datepicker', {
    attrs: {
      "id": "to-datepicker",
      "value-as-date": true,
      "locale": "en-GB",
      "min": _vm.fromDate,
      "max": new Date(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.toDate,
      callback: function callback($$v) {
        _vm.toDate = $$v;
      },
      expression: "toDate"
    }
  }), _c('b-form-timepicker', {
    attrs: {
      "locale": "en",
      "no-close-button": ""
    },
    model: {
      value: _vm.toTime,
      callback: function callback($$v) {
        _vm.toTime = $$v;
      },
      expression: "toTime"
    }
  })], 1)])], 1)], 1)], 1), _c('hr'), _c('b-table', {
    attrs: {
      "id": "prices-table",
      "stacked": "md",
      "hover": "",
      "busy": _vm.loading,
      "items": _vm.pricesDataForTable,
      "fields": _vm.fields
    },
    on: {
      "update:busy": function updateBusy($event) {
        _vm.loading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('vue-loaders-ball-beat', {
          attrs: {
            "color": "var(--redstone-red-color)",
            "scale": "1"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(value)",
      fn: function fn(data) {
        return [!_vm.isCurrencyToken(_vm.tokenDetails) ? _c('div', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(data.item.value) + " ")]) : _c('div', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("price")(data.item.value, {
          currency: _vm.getCurrency(_vm.tokenDetails),
          decimals: _vm.priceDecimals()
        })) + " ")])];
      }
    }, {
      key: "cell(time)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "time"
        }, [_vm._v(" " + _vm._s(data.item.time) + " ")])];
      }
    }, {
      key: "cell(providerId)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "tx-link d-flex flex-column flex-md-row align-items-md-center"
        }, [_c('div', {
          staticClass: "link align-center mt-2 mt-md-0"
        }, [_vm._v(" " + _vm._s(data.item.providerId) + " ")])])];
      }
    }, {
      key: "cell(dispute)",
      fn: function fn(data) {
        return [_c('b-btn', {
          attrs: {
            "target": "_blank",
            "variant": "dispute",
            "disabled": false
          },
          on: {
            "click": function click($event) {
              return _vm.showNotification('The disputing feature is still under development');
            }
          }
        }, [_vm._v(" Raise dispute ")])];
      }
    }])
  }), _vm.prices.length > 0 && _vm.prices[0].provider != _vm.DEFAULT_PROVIDER() ? _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.loadMoreButtonVisibilityChanged,
      expression: "loadMoreButtonVisibilityChanged"
    }],
    staticClass: "load-more-link-container"
  }, [_vm.loadingMore ? _c('div', {
    staticClass: "loading-more-container"
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "#3e86ca",
      "scale": "0.5"
    }
  })], 1) : _vm._e()]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }