var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crypto-dropdown-container"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "dropdown crypto-dropdown",
    attrs: {
      "text": _vm.buttonText,
      "multiple": ""
    },
    on: {
      "shown": _vm.onDropdownShown
    }
  }, [_c('div', {
    staticClass: "search-input-container"
  }, [_c('b-form-input', {
    ref: "searchInput",
    staticClass: "pr-4",
    attrs: {
      "variant": "danger",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _c('b-dropdown-form', [_c('b-form-checkbox-group', {
    staticClass: "crypto-checkbox-group",
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.tempSelectedCryptos,
      callback: function callback($$v) {
        _vm.tempSelectedCryptos = $$v;
      },
      expression: "tempSelectedCryptos"
    }
  }, _vm._l(_vm.filteredCryptoImageData, function (crypto) {
    return _c('b-form-checkbox', {
      key: crypto.token,
      staticClass: "crypto-checkbox-list",
      attrs: {
        "variant": "danger",
        "value": crypto.token
      }
    }, [_c('div', {
      staticClass: "crypto-name"
    }, [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "title": crypto.name,
        "src": crypto.logoURI,
        "alt": crypto.name,
        "width": "20",
        "height": "20"
      }
    }), _c('span', {
      attrs: {
        "title": crypto.name
      }
    }, [_vm._v(_vm._s(crypto.token))])], 1)]);
  }), 1), _vm.filteredCryptoImageData.length === 0 ? _c('span', {
    staticClass: "no-results"
  }, [_vm._v("No results found")]) : _vm._e()], 1), _vm.hasChanges ? _c('div', {
    staticClass: "confirm-button-container"
  }, [_c('b-button', {
    staticClass: "confirm-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.confirmChanges
    }
  }, [_c('i', {
    staticClass: "fa fa-icon fa-sliders mr-2"
  }), _vm._v("Filter (" + _vm._s(_vm.tempSelectedCryptos.length) + ") ")])], 1) : _vm.value.length > 0 ? _c('div', {
    staticClass: "confirm-button-container"
  }, [_c('b-button', {
    staticClass: "reset-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.resetChanges
    }
  }, [_vm._v("Reset (" + _vm._s(_vm.tempSelectedCryptos.length) + ")")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }