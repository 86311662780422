var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-lg"
  }, [_c('b-row', _vm._l(_vm.visibleTokens, function (token, index) {
    return _c('b-col', {
      key: index,
      staticClass: "py-1 py-md-2",
      attrs: {
        "xxl": "3",
        "xl": "4",
        "lg": "6",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('div', {
      staticClass: "pb-xlg",
      on: {
        "click": function click($event) {
          _vm.$router.push("/app/token/".concat(token.symbol.includes('/') ? token.symbol.replace('/', '\\') : token.symbol));
        }
      }
    }, [_c('Widget', {
      staticClass: "mb-0 token-card"
    }, [_c('b-row', {
      staticClass: "token-details"
    }, [_c('b-col', {
      staticClass: "token-logo",
      attrs: {
        "cols": "2"
      }
    }, [token.logoURI ? _c('img', {
      attrs: {
        "src": token.logoURI,
        "loading": "lazy"
      }
    }) : _c('span', {
      staticClass: "no-token-emoji"
    }, [_vm._v("🤔")])]), _vm.isNotCurrencyToken(token) ? _c('b-col', {
      staticClass: "h4 token-title pr-0",
      attrs: {
        "cols": "5",
        "sm": "4",
        "md": "6"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("maxLength")(token.symbol, 15)) + " "), _c('br'), _c('div', {
      staticClass: "token-name"
    }, [_vm._v(" " + _vm._s(token.name) + " ")])]) : _c('b-col', {
      staticClass: "h4 token-title pr-0",
      attrs: {
        "cols": "5",
        "sm": "4",
        "md": "6"
      }
    }, [_vm._v(" " + _vm._s(token.symbol) + " "), _c('br'), _c('div', {
      staticClass: "token-name"
    }, [_vm._v(" " + _vm._s(token.name) + " ")])]), _c('b-col', {
      staticClass: "token-price pl-0",
      attrs: {
        "cols": "5",
        "sm": "6",
        "md": "4"
      }
    }, [(_vm.prices[token.symbol] || _vm.prices[token.symbol] === 0) && _vm.isNotCurrencyToken(token) ? _c('span', [_vm._v(" " + _vm._s(_vm._f("maxLength")(_vm._f("value")(_vm.prices[token.symbol], {
      eNotationForSmallValues: true
    }), 8)) + " ")]) : _vm.prices[token.symbol] || _vm.prices[token.symbol] === 0 ? _c('span', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.prices[token.symbol], {
      eNotationForSmallValues: true,
      currency: _vm.getCurrency(token)
    })) + " ")]) : _c('vue-loaders-ball-beat', {
      attrs: {
        "color": "var(--redstone-red-color)",
        "scale": "0.5"
      }
    })], 1)], 1), _c('b-row', {
      staticClass: "d-flex justify-content-end see-more-wrapper"
    }, [_c('div', {
      staticClass: "see-more"
    }, [_vm._v(" See more"), _c('i', {
      staticClass: "fa fa-angle-right"
    })])])], 1)], 1)]);
  }), 1), _c('b-row', [!_vm.allTokensVisible ? _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.loadMoreSectionVisibilityChanged,
      expression: "loadMoreSectionVisibilityChanged"
    }],
    staticClass: "loading-more-container"
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "0.5"
    }
  })], 1) : _vm._e()])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }